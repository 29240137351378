import { Carousel, Typography, Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import towerBridge from "../static/images/tower-bridge.jpeg";
import foodAndDrink from "../static/images/food-drink.jpeg";
import hotel from "../static/images/hotel.jpeg";

export default function CarouselWithContent() {
  const { t } = useTranslation();

  const slides = [
    {
      pic: towerBridge,
      title: t('carousel.slide1.title'),
      description: t('carousel.slide1.description'),
      buttonTitle: t('carousel.slide1.buttonTitle')
    },
    {
      pic: foodAndDrink,
      title: t('carousel.slide2.title'),
      description: t('carousel.slide2.description'),
      buttonTitle: t('carousel.slide2.buttonTitle')
    },
    {
      pic: hotel,
      title: t('carousel.slide3.title'),
      description: t('carousel.slide3.description'),
      buttonTitle: t('carousel.slide3.buttonTitle')
    }
  ]

  return (
    <Carousel className="rounded-xl h-screen">
      {
        slides.map(({ pic, title, description, buttonTitle }) => (
          <div className="relative h-full w-full" >
            <img
              src={pic}
              alt="London Tower Bridge"
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
              <div className="w-3/4 md:w-3/4">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-2xl md:text-3xl lg:text-4xl text-center"
                >
                  {title}
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80 text-base md:text-xl lg:text-2xl"
                >
                  {description}
                </Typography>
                <div className="flex justify-center gap-2">
                  <Button size="lg" color="white">
                    {buttonTitle}
                  </Button>
                  {/* <Button size="lg" color="white" variant="text">
                Gallery
              </Button> */}
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </Carousel>
  );
}
