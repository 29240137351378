import Header
  from "./components/Header";
import Hero from "./components/Hero";
import NewsLetter from "./components/NewsLetter";
import Stats from "./components/Stats";
import Footer from "./components/Footer";
import GettingJumbotron from "./components/GettingHereJumbotron";
import CarouselWithContent from "./components/Carousel";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <GettingJumbotron />
      <Stats />
      <CarouselWithContent />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default App;
